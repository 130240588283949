import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./casestudymicrosoftbusapps.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-msbusapps-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-8">
                        <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 2}
                            height={pageContext.acf.client_logo.media_details.height / 2}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/11/microsoft-business-applications-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                        {/* <a href="https://www.meritmile.com/uploads/2019/03/quackSkins-and-merit-mile-case-study.pdf" className="btn sitebtn" target="_blank" rel="nofollow noopener noreferrer">Read the Full Case Study</a> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img
                            src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src}
                            className="desktop-image img-fluid"
                            width={pageContext.acf.desktop_image.media_details.width / 1.5}
                            height={pageContext.acf.desktop_image.media_details.height / 1.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-6">
                        <div
                            className="second-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.second_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <p>In under three months, Merit Mile developed a custom collection of marketing and sales collateral for each partner. Partners received a multi-page solution brief, customer presentation, case study, and SEO optimized web copy for their marketplace listing.</p>
                        <p>Merit Mile's project management team lead governance, including workflows, communications, and weekly reporting with each partner and Microsoft project sponsors. Microsoft Teams and Microsoft 365 were used for inter-project collaboration, real-time reporting, content reviews, and digital organization. Throughout the project, Microsoft stakeholders knew their program was not only being embraced by selected partners, but also made aware of any unique requests and requirements.</p>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/msma-results.png"
                            className="results-image img-fluid"
                            alt="Microsoft Business Applications Case Study Results"
                            width="616px"
                        />
                    </div>
                </div>
                <div className="row margin-b quote-paragraph">
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/quote-img.png"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable"
                        />
                    </div>
                    <div className="col-md-6">
                        <blockquote>
                            Thank you Merit Mile team, we're looking forward to future collaborations!
                        </blockquote>
                        <cite>Monica M., Microsoft Corp.</cite>
                        <p>The creative team at Merit Mile, including writers and artists, brought fresh words and visuals to life, highlighting each partner's offerings with first class design work. Everything was co-branded with Microsoft, reinforcing the strategic relationship between the partners and Microsoft.</p>
                    </div>
                </div>
            </div>
            <div className="container wider detail-wrapper">
                <div className="row four-samples margin-b">
                    <div className="col-md-3">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cs-01.png"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable 2"
                        />
                    </div>
                    <div className="col-md-3">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cs-02.png"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable 2"
                        />
                    </div>
                    <div className="col-md-3">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cs-03.png"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable 2"
                        />
                    </div>
                    <div className="col-md-3">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cs-04.png"
                            className="quote-image img-fluid"
                            alt="Sample of Partner Branded Deliverable 2"
                        />
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row fifth-paragraph margin-b">
                    <div className="col-md-6">
                        <div className="">
                            <h3><b style={{ fontWeight: '400', fontStyle: 'italic' }}>Merit Mile Services</b></h3>
                            <ul>
                                <li>
                                    Marketing strategy
                                </li>
                                <li>
                                    Project governance
                                </li>
                                <li>
                                    Project management 
                                </li>
                                <li>
                                    Copywriting
                                </li>
                                <li>
                                    Creative design
                                </li>
                                <li>
                                    SEO
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                        <h3><b style={{ fontWeight: '400', fontStyle: 'italic' }}>Deliverables</b></h3>
                            <ul>
                                <li>
                                    Solution briefs
                                </li>
                                <li>
                                    Presentations
                                </li>
                                <li>
                                    Case studies
                                </li>
                                <li>
                                    Marketplace web copy
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div class="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your content marketing, lead generation, and brand awareness goals by contacting <a href="mailto:Hello@MeritMile.com?subject=BizApps%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div class="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/jh-thumb.jpg"
                                        alt="Janice Hughes"
                                        className="img-fluid"
                                    />
                                    <h4>Janice Hughes</h4>
                                    <p>
                                        Technology<br />
                                        Copywriter<br />
                                        &nbsp;
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=BizApps%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
